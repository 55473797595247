.Home {
    display: flex;
    flex-direction: column;
}

.Home .NavGroup {
    margin-bottom: 20px;
}

@media only screen and (min-width: 512px) {
    .Home .NavGroup {
        margin-bottom: 0;
    }
}

.Home .activity-pane {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* I don't understand this and hate it, but mobile views have a y-scroll otherwise */
@media only screen and (max-width: 512px) {
    .Home > .home-left > .Card {
        padding: 0 20px;
    }
    .Home > .home-right {
        padding: 20px 0;
    }
}

@media only screen and (max-width: 1024px) {
    .Home > .home-left {
        flex-grow: 1;
    }
}

@media only screen and (min-width: 1024px) {
    .Home {
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: flex-start;
    }

    .Home .home-left {
        width: 50%;
        margin-right: 20px;
        padding-top: 0 !important;
    }

    .Home .home-right {
        width: 50%;
    }
}
