.NavSection {
    display: flex;
    margin-top: 40px;
}

.NavSection .nav-section-icon {
    padding-right: 30px;
}

.NavSection .nav-section-title {
    font-size: 1.125rem;
    margin-bottom: 10px;
}

.NavSection .nav-section-icon svg {
    margin: 0 auto;
}

.NavSection .nav-section-description {
    color: var(--text-secondary);
    font-size: 0.875rem;
}

@media only screen and (min-width: 512px) {
    .NavSection:first-child {
        margin-top: 10px;
    }
}
