.RecipeSearch {
    background: var(--content-background-panels);
}

.RecipeSearch .loading {
    opacity: 0.2;
}

.RecipeSearch .SearchBar {
    padding: 5px 10px;
    margin: 0 10px;
}
