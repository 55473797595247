.ActivityDetails a {
    font-weight: bold;
}

.ActivityDetails .ActivityRecipeCardRow {
    padding: 20px 0;
    border-bottom: 1px solid var(--separator-color);
}

.ActivityDetails .activity-recipe-card-row-header {
    margin-bottom: 5px;
}

.ActivityDetails {
    color: var(--text-secondary);
}

.ActivityDetails .user-section {
    display: flex;
}


.ActivityDetails .user-section > div {
    margin-left: 20px;
}
