.RecipeInfo .title {
    font-family: 'Libre Baskerville';
    font-size: 2em;
    font-weight: 700;
}

.RecipeInfo .directions {
    color: var(--text-secondary);
    margin: 20px 0 10px 0;
    line-height: 1.5em;
}

.RecipeInfo .TagList {
    margin-top: 15px;
    margin-bottom: 15px;
}
