.ListRecipeCard {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d1d1e9;
}

.ListRecipeCard .recipe-name {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 5px;
}

.ListRecipeCard .lr-card-title-row {
    display: flex;
    justify-content: space-between;
}

.ListRecipeCard .lr-card-title-row svg {
    cursor: pointer;
    color: var(--text-secondary);
}

.ListRecipeCard .list-recipe-edit-note .FormWrapper {
    margin-bottom: 0;
}

.ListRecipeCard .list-recipe-edit-note {
    margin-top: 10px;
    margin-bottom: 30px;
}

.ListRecipeCard .list-recipe-edit-note .Button {
    margin-top: 10px;
    float: right;
}

.ListRecipeCard .list-recipe-note {
    display: flex;
    flex-direction: column;
}

.ListRecipeCard .list-recipe-note .Button {
    display: inline-block;
    padding-left: 0;
}

.ListRecipeCard .list-recipe-note .Description {
    margin-bottom: 5px;
}
