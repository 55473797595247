:root {
    /* Primary theme colors */
    --theme-primary: #553c9a;
    --theme-primary-focus: #269d6c;
    --theme-secondary: #2cb67d;
    --theme-tertiary: #d1d1e9;
    --theme-danger: #e45858;
    --theme-danger-focus: #c82020;
    --theme-muted: #72757a;
    --theme-dark: #16161a;
    --theme-light: #fffffe;

    /* Typography */
    --text-large: 20px;
    --text-medium: 16px;
    --text-small: 14px;

    /* Spacing */
    /* TODO */

    /* Common */
    --content-background-menu: #2b2c34;
    --text-tertiary: #a0aec0;

    /* Light Mode */
    --light-body-background: #d1d1e9;
    --light-text-primary: #2b2c34;
    --light-text-secondary: #72757a;
    --light-text-inverse: #fffffe;
    --light-links-color: #553c9a;
    --light-button-outline: #553c9a;
    --light-content-background-panels: #fffffe;
    --light-content-background-forms: #f5f5f5;
    --light-separator-color: #d1d1e9;

    /* Dark Mode */
    --dark-body-background: #16161a;
    --dark-text-primary: #fffffe;
    --dark-text-secondary: #c7cdd8;
    --dark-text-inverse: #2b2c34;
    --dark-links-color: #fffffe;
    --dark-button-outline: #d1d1e9;
    --dark-content-background-panels: #2b2c34;
    --dark-content-background-forms: #2b2c34;
    --dark-separator-color: #72757a;

    /* Component colors (light mode default) */
    --body-background: var(--light-body-background);
    --text-primary: var(--light-text-primary);
    --text-secondary: var(--light-text-secondary);
    --text-inverse: var(--light-text-inverse);
    --links-color: var(--light-links-color);
    --button-outline: var(--light-button-outline);
    --content-background-panels: var(--light-content-background-panels);
    --content-background-forms: var(--light-content-background-forms);
    --separator-color: var(--light-separator-color);
}

/* Dark mode colors */
@media (prefers-color-scheme: dark) {
    :root {
        --body-background: var(--dark-body-background);
        --text-primary: var(--dark-text-primary);
        --text-secondary: var(--dark-text-secondary);
        --text-inverse: var(--dark-text-inverse);
        --links-color: var(--dark-links-color);
        --button-outline: var(--dark-button-outline);
        --content-background-panels: var(--dark-content-background-panels);
        --content-background-forms: var(--dark-content-background-forms);
        --separator-color: var(--dark-separator-color);
    }

    .light-mode {
        --body-background: var(--light-body-background);
        --text-primary: var(--light-text-primary);
        --text-secondary: var(--light-text-secondary);
        --text-inverse: var(--light-text-inverse);
        --links-color: var(--light-links-color);
        --button-outline: var(--light-button-outline);
        --content-background-panels: var(--light-content-background-panels);
        --content-background-forms: var(--light-content-background-forms);
        --separator-color: var(--light-separator-color);
    }
}

.dark-mode {
    --body-background: var(--dark-body-background);
    --text-primary: var(--dark-text-primary);
    --text-secondary: var(--dark-text-secondary);
    --text-inverse: var(--dark-text-inverse);
    --links-color: var(--dark-links-color);
    --button-outline: var(--dark-button-outline);
    --content-background-panels: var(--dark-content-background-panels);
    --content-background-forms: var(--dark-content-background-forms);
    --separator-color: var(--dark-separator-color);
}
