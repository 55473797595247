body {
    margin: 0;
    max-width: 100vw;
}

*:focus {
    outline: none;
}

body {
    background-color: var(--content-background-panels);
    color: var(--text-primary);
    font-family: 'Roboto script=all rev=1', sans-serif;
    line-height: 1.25;
}

@media only screen and (min-width: 512px) {
    body {
        background-color: var(--body-background);
    }
}

.App a {
    color: var(--links-color);
    text-decoration: none;
    font-weight: 500;
}

.App div.page-container {
    max-width: 800px;
    margin-left: auto !important;
    margin-right: auto !important;
}

@media only screen and (min-width: 512px) and (max-width: 840px) {
    .App div.page-container {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
}

@media only screen and (min-width: 512px) {
    .App .AppContent > div:not(.Login) {
        margin: 20px 20px 0 20px;
    }
}
