.RecipeFilters {
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.3) inset, 0 -5px 5px -5px rgba(0, 0, 0, 0.3) inset;
    padding: 20px;
    background-color: var(--content-background-forms);
    margin-bottom: 10px;
}

.RecipeFilters .FormWrapper {
    margin-bottom: 15px;
}

.RecipeFilters .Forms select.Select {
    background-color: var(--content-background-forms);
}

/*
 * Expanding Details Transition
 */

.SearchBar .openSection-enter {
  opacity: 0;
  max-height: 0;
}

.SearchBar .openSection-enter-active {
  opacity: 1;
  max-height: 252px;
  overflow-y: hidden;
  transition: max-height 200ms ease-in, opacity 300ms ease-in;
}

.SearchBar .openSection-exit {
  opacity: 1;
  max-height: 252px;
  /* This prevents there being a jitter at the end. I hate it though. */
  margin-bottom: -40px;
}

.SearchBar .openSection-exit-active {
  opacity: 0;
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 200ms ease-out, opacity 100ms ease-out;
}

