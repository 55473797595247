.IngredientRow span {
    color: var(--text-secondary);
    line-height: 1.5;
    margin-right: 7px;
}

.IngredientRow span:not(:last-child)::after {
    content: '•';
    vertical-align: middle;
    margin-left: 10px;
}


