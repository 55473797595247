.ManageLists {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 0.875rem;
}

.ManageLists .list-links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 30px;
}

.ManageLists .list-links .list-link {
    margin-right: 10px;
}

.ManageLists .list-links .list-link:after {
    content: '•';
    vertical-align: middle;
    margin-left: 10px;
}

.ManageLists .ManageListsRow {
    display: flex;
    align-items: center;
    padding: 20px;
    border-top: 1px solid var(--separator-color);
    cursor: pointer;
}

.ManageLists .ManageListsRow:last-child {
    border-bottom: 1px solid var(--separator-color);
}

.ManageLists .ManageListsRow .list-select {
    margin-right: 10px;
}


