.App-header {
    background-color: var(--theme-primary);
    display: flex;
    justify-content: space-between;
    color: var(--theme-light);
}

.App header.simple {
    justify-content: center;
    padding-left: 14px;
}

.App .App-header {
    padding: 20px 20px;
    font-size: 1.5rem;
}

.App .App-header a {
    color: var(--theme-light);
    padding-right: 5px;
    text-decoration: none;
}

.App .App-header a.home-link {
    font-family: 'Niconne',cursive;
}

.App .App-header .header-back {
    cursor: pointer;
}

.App .App-header .header-menu {
    cursor: pointer;
}

.App .App-header .HelpContent {
    color: var(--text-primary);
}

.App .App-header .HelpContent a {
    color: var(--links-color);
    text-decoration: underline;
}

/*
 * On progressive web apps, use a sticky header
 * https://gist.github.com/sconstantinides/221a9ae6bf8a1d2bc02f1e5d5d5ddf61
 */
@media only screen and (max-width: 512px) and (display-mode: fullscreen) {
    .App .App-header {
        padding-top: 14px;
        padding-bottom: 14px;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 289;
    }

    /* Extra padding for iOS devices to show the status bar */
    @supports (-webkit-overflow-scrolling: touch) {
        .App .App-header {
            padding-top: 48px;
        }
    }
}
