.RecipeCard {
    padding: 20px;
    border-bottom: 1px solid var(--separator-color);
    cursor: pointer;
}

.Card.RecipeCard {
    margin: 0;
}

.RecipeCard .recipe-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 5px;
}

.RecipeCard .badges {
    float: right;
    display: flex;
    align-items: center;
}

.RecipeCard .ul-count {
    font-size: 1.5em;
    color: var(--theme-tertiary);
}

.RecipeCard .comment-count {
    border-radius: 2em;
    font-size: 1em;
    margin-top: -5px;
    padding: 5px 10px;
    margin-left: 10px;
    border: 1px solid var(--theme-tertiary);
    color: var(--theme-tertiary);
}

.RecipeCard .comment-count.uc {
    background-color: var(--theme-tertiary);
    color: var(--text-inverse);
}
