.Lists .ListInfo {
    flex-direction: column;
}

.Lists .ListInfo .Card:last-child {
    margin-left: 0;
}

.Lists .ListInfo > div {
    min-width: calc(100% - 40px);
}

.ListOfLists {
    background-color: var(--content-background-panels);
}

.ListOfLists .list-of-lists-header {
    padding: 20px;
    background-color: var(--content-background-panels);
}

.ListOfLists .list-of-lists-header .new-list {
    margin-top: 20px;
}

.ListCard {
    padding: 20px;
    border-bottom: 1px solid var(--separator-color);
    cursor: pointer;
}

.ListCard .list-name {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 5px;
}

.ListCard .list-description {
    color: var(--text-secondary);
}
