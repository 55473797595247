.SearchBar {
    padding: 5px 0;
}

.SearchBar .search-bar {
    position: relative;
    width: 100%;
}

.SearchBar .FormWrapper .Input {
    background-color: var(--content-background-forms);
}

.SearchBar .FormWrapper .Input:focus {
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-left: 1px solid rgba(0, 0, 0, 0);
    border-right: 1px solid rgba(0, 0, 0, 0);
}

.SearchBar .FormWrapper {
    margin-top: 10px;
    margin-bottom: 10px;
}

.SearchBar .search-bar .ButtonRow {
    position: absolute;
    top: 3px;
    right: 0;
    font-size: 16px;
}

.SearchBar .SearchHelp .help-body {
    padding: 0 20px;
}

.SearchBar .SearchHelp .help-body .SectionTitle {
    margin-top: 50px;
}
