.Alerts {
    z-index: 10000;
    position: absolute;
    top: 100px;
    left: calc(50% - 40vw);
    width: 80vw;
}

.Alert {
    background-color: var(--content-background-forms);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--theme-muted);
    border-bottom: 1px solid var(--theme-muted);
    border-right: 1px solid var(--theme-muted);
    box-shadow: 0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04);
}

.Alert.alert-warn,
.Alert.alert-error {
    border-left: 5px solid var(--theme-danger);
}

.Alert.alert-success {
    border-left: 5px solid var(--theme-secondary);
}
