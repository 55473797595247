.UserDetails .first-pane {
    min-width: 40%;
}

.UserDetails .second-pane {
    flex-grow: 1;
}

.UserDetails .user-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.UserDetails .user-data .PageTitle {
    margin-top: 10px;
}

@media only screen and (min-width: 512px) {
    .UserDetails .user-data {
        flex-direction: row;
        align-items: flex-start;
        text-align: left;
    }

    .UserDetails .user-details {
        margin-left: 20px;
    }
}

@media only screen and (min-width: 1024px) {
    .UserDetails .first-pane {
        max-width: 40%;
    }
}

.UserDetails .user-lists {
    background-color: var(--content-background-panels);
    padding-bottom: 25px;
    margin-bottom: 20px;
}

.UserDetails .edit-link {
    margin-top: 20px;
}

.UserDetails .list-link {
    margin-top: 20px;
    text-align: center;
}

.UserDetails .user-stats > div {
    margin-top: 5px;
    color: var(--text-secondary);
}
