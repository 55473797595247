.ManageTags {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    min-height: 60px;
}

.ManageTags.editing > * {
    margin-right: 10px;
}

.ManageTags.show-button > button {
    color: var(--text-tertiary);
    padding-left: 0;
}
