.RecipeMenu {
    position: relative;
}

.RecipeMenu .recipe-menu-button {
    font-size: 1.5rem;
    float: right;
}

.RecipeMenu .recipe-menu {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 180px;
    z-index: 20;
    text-align: left;
    background-color: var(--content-background-panels);
    box-shadow: 0 20px 25px -5px rgba(0,0,0,.1),0 10px 10px -5px rgba(0,0,0,.04);
    border: 1px solid var(--separator-color);
}

.RecipeMenu .recipe-menu > div {
    padding: 10px 20px;
    cursor: pointer;
}

.RecipeMenu .recipe-menu .selected-multiplier {
    background-color: var(--theme-secondary);
    color: var(--theme-light);
}

.RecipeMenu .recipe-menu .selected-multiplier:hover {
    background-color: var(--theme-muted);
    color: var(--theme-light);
}

.RecipeMenu .recipe-menu > div:hover {
    background-color: var(--theme-secondary);
    color: var(--text-inverse);
}

.RecipeMenu .recipe-menu a {
    color: var(--theme-dark);
}

.RecipeMenu .recipe-menu a:hover {
    color: var(--theme-light);
}

.RecipeMenu .recipe-menu div.bordered {
    border-top: 1px solid var(--theme-muted);
}
