.ActivityRow:not(:last-child) {
    border-bottom: 1px solid var(--separator-color);
}

.ActivityRow {
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
}

.ActivityRow .activity-header-img {
    min-width: 52px;
    margin-right: 15px;
}

.ActivityRow .activity-header-userlink {
    margin-right: 5px;
}

.ActivityRow .activity-header-date {
    margin-top: 5px;
    font-size: 14px;
}

.ActivityRow .activity-body {
    color: var(--text-secondary);
    margin-top: 10px;
    line-height: 1.25rem;
    max-width: 100%;
    white-space: pre-wrap;
}

.ActivityRow .activity-body a {
    font-weight: bold;
}

@media only screen and (max-width: 350px) {
    .ActivityRow .activity-header-text {
        max-width: 220px;
        overflow-wrap: break-word;
    }
}

.ActivityRow .activity-details-link {
    margin-top: 15px;
}
