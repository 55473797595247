.CategoryRow {
    padding: 15px 0 15px 0;
    border-bottom: 1px solid var(--separator-color);
    cursor: pointer;
    font-size: 1.125rem;
}

.CategoryList .categories {
    margin-top: 20px;
}

.UserCabinetCategory .category-title {
    font-size: 1.125rem;
    margin-bottom: 15px;
}

.IngredientCabinetRow {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 10px 0;
    border-bottom: 1px solid var(--separator-color);
}

.IngredientCabinetRow.category-header {
    color: var(--text-secondary);
}

.IngredientCabinetRow .CheckBox {
    margin-right: 10px;
}

.IngredientCabinetRow > div:first-child {
    flex-grow: 1;
    display: flex;
}

.IngredientCabinetRow > div:last-child {
    text-align: right;
}
