/*
 * FormWrapper
 */

.FormWrapper {
    margin-bottom: 40px;
}

.Forms.FormWrapper label {
    font-size: 0.875rem;
    text-transform: uppercase;
    color: var(--text-secondary);
    display:inline-block;
    margin-bottom: 10px;
}

.Forms.FormWrapper .subtext {
    margin-top: 5px;
    font-size: 0.875rem;
    color: var(--text-secondary);
    font-style: italic;
}

.Forms.FormWrapper .error-message {
    margin-top: 5px;
    font-size: 0.875rem;
    color: var(--theme-danger);
    font-style: italic;
}

.Forms.FormWrapper.form-error .Input,
.Forms.FormWrapper.form-error .TextArea,
.Forms.FormWrapper.form-error .Select {
    border-bottom: 2px solid var(--theme-danger);
}


/* TODO: Make Input/TextArea/Select attributes common */

/*
 * Common
 */

.Forms .Input,
.Forms .TextArea,
.Forms .Select {
    color: var(--text-primary);
    background-color: var(--content-background-forms);
    outline: none;
    appearance: none;
    line-height: 1.25;
    font-size: 16px;
    -moz-appearance: none;
    -webkit-appearance: none;
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-left: 1px solid rgba(0, 0, 0, 0);
    border-right: 1px solid rgba(0, 0, 0, 0);
    border-bottom: 2px solid var(--theme-secondary);
    width: calc(100% - 20px);
    padding: 10px 10px;
    font-family: 'Roboto script=all rev=1', sans-serif;
}

.Forms .Input:focus,
.Forms .TextArea:focus,
.Forms .Select:focus {
    border-top: 1px solid rgba(44, 182, 125, 0.2);
    border-left: 1px solid rgba(44, 182, 125, 0.2);
    border-right: 1px solid rgba(44, 182, 125, 0.2);
    background-color: var(--content-background-forms);
}

/*
 * Input
 */

.Input:focus::placeholder {
    color: transparent;
}

/*
 * TextArea
 */
.Forms .TextArea {
    height: 2rem;
    resize: vertical;
    transition: height 0.2s ease-out;
    -moz-transition: height 0.2s ease-out;
    -webkit-transition: height 0.2s ease-out;
}

.Forms .TextArea.textarea-large {
    height: 8.5rem;
}

.TextArea::-webkit-input-placeholder {
    font-family: 'Roboto script=all rev=1', sans-serif;
    color: var(--text-secondary);
}

.TextArea:-ms-input-placeholder {
    font-family: 'Roboto script=all rev=1', sans-serif;
    color: var(--text-secondary);
}

.TextArea:-moz-placeholder {
    font-family: 'Roboto script=all rev=1', sans-serif;
    color: var(--text-secondary);
}

.TextArea::-moz-placeholder {
    font-family: 'Roboto script=all rev=1', sans-serif;
    color: var(--text-secondary);
}

.TextArea:focus::placeholder {
  color: transparent;
}

/*
 * Select
 */

.Forms.FormWrapper .Select {
    width: 100%;
}

/*
 * Radio Button
 */

.FormWrapper .radio-button-choice {
}

.FormWrapper .radio-button-choice label {
    font-size: 12px;
    margin-left: 5px;
}

.FormWrapper .radio-button-choice input[type='radio'] {
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    outline: none;
    box-shadow: 0 0 0 3px var(--theme-muted);
}

.FormWrapper .radio-button-choice input[type='radio']:checked {
    box-shadow: 0 0 0 3px var(--theme-secondary);
}

/*
 * Button
 */

.Button {
    appearance: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 0.875rem;
}

.Button.small {
    padding: 5px 8px;
}

.Button.button-outline {
    color: var(--button-outline);
    border: 1px solid var(--button-outline);
    background-color: var(--content-background-panels);
}

.Button.button-outline:focus,
.Button.button-outline:hover {
    color: var(--text-inverse);
    background-color: var(--button-outline);
}

.Button.button-primary {
    color: var(--theme-light);
    border: 1px solid var(--theme-secondary);
    background-color: var(--theme-secondary);
}

.Button.button-primary:focus,
.Button.button-primary:hover {
    background-color: var(--theme-primary-focus);
    border: 1px solid var(--theme-primary-focus);
}

.Button.button-primary-outline {
    color: var(--theme-secondary);
    border: 1px solid var(--theme-secondary);
    background-color: var(--theme-light);
}

.Button.button-primary-outline:focus,
.Button.button-primary-outline:hover {
    color: var(--theme-light);
    background-color: var(--theme-secondary);
}

.Button.button-danger {
    color: var(--theme-light);
    border: 1px solid var(--theme-danger);
    background-color: var(--theme-danger);
}

.Button.button-danger:focus,
.Button.button-danger:hover {
    background-color: var(--theme-danger-secondary);
    border: 1px solid var(--theme-danger-secondary);
}

.Button.button-clear {
    color: var(--links-color);
    background-color: rgba(255, 255, 255, 0.0);
    border: none;
}

.ButtonRow {
    display: flex;
    justify-content: space-between;
}

.CheckBox.selected {
    color: var(--theme-secondary);
}
