.QuantitySet > .Forms.FormWrapper {
    margin-bottom: 0;
}

.QuantityRow {
    display: flex;
    background-color: var(--content-background-panels);
}

.QuantityRow.dragging {
    border: 1px solid var(--separator-color);
    opacity: 0.5;
}

.QuantityRow .amount,
.QuantityRow .unit {
    width: 20%;
    margin-right: 10px;
}

.QuantityRow .ingredient {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
}

.QuantityRow .ingredient .AutoComplete {
    flex-grow: 1;
    margin-right: 10px;
}

.QuantityRow .reorder {
    cursor: grab;
    color: var(--text-secondary);
    padding: 15px;
    padding-left: 0;
}

/* Drag and drop HTML5 API doesn't work on mobile, so don't show it */
@media (pointer:coarse) {
    .QuantityRow .reorder {
        display: none;
    }
}

.QuantityRow .ingredient .remove-quantity {
    cursor: pointer;
    color: var(--text-secondary);
    margin-top: 10px;
    padding: 5px;
    padding-right: 0;
}
