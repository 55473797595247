.SearchPills {
    padding: 10px 20px 15px 20px;
    display: flex;
    flex-wrap: wrap;
}

.SearchPills .pill {
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    background-color: var(--theme-secondary);
    color: var(--theme-light);
}

.SearchPills .pill.negation {
    background-color: var(--theme-danger);
}

.SearchPills .pill.constraint {
    background-color: var(--theme-primary);
}
