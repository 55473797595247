.TagList {
}

.TagList > span {
    border-radius: 1em;
    padding: 3px 6px;
    font-size: 0.75em;
    color: var(--text-inverse);
    background-color: var(--theme-tertiary);
    margin-right: 5px;
}

.TagList > span svg {
    font-size: 10px;
    margin-left: 5px;
    cursor: pointer;
}

.TagListEdit {
    display: flex;
    align-items: center;
}

.TagListEdit .FormWrapper {
    margin-bottom: 0;
}

.TagListEdit .Input {
    background-color: var(--content-background-forms);
    border-bottom: 1px solid var(--theme-secondary);
}

.TagListEdit .AutoComplete {
    width: 100px;
}


.TagListEdit .TagList > span {
    padding: 5px 10px;
    font-size: 0.875em;
}
